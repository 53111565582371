<template>
  <base-section id="k-d-a-home-solution" space="80" top-inner-shadow>
    <v-container class="pa-0">
      <v-row no-gutters class="px-9">
        <v-col>
          <component
            :is="m_objTitle.strComponent"
            :class="`kda-ts-${g_bLowerBr ? '28' : '64'}pt wt-extrabold ln-150 text-center`"
            v-html="m_objTitle.htmlText"
          />
        </v-col>
      </v-row>

      <v-row no-gutters style="padding-top: 40px">
        <v-col v-for="solution in m_arrSolutions" :key="solution.strTitle" cols="12">
          <v-row
            no-gutters
            align="center"
            justify="center"
            :style="{ padding: `${g_bLowerBr ? '28px 32px' : '38px 48px'}` }"
          >
            <v-col cols="auto">
              <base-img
                :src="solution.imgSrc"
                :class="`mr-${g_bLowestBr ? '4' : '8'}`"
                position="left"
                contain
                :max-height="g_bLowestBr ? 100 : 174"
                :max-width="g_bLowestBr ? 100 : '100%'"
              ></base-img>
            </v-col>
            <v-col cols="7" class="col-md-8">
              <h4 :class="`${g_bLowestBr ? 'kda-ts-20pt' : 'kda-ts-28pt mb-4'} wt-extrabold`">
                {{ solution.strTitle }}
              </h4>

              <p v-if="!g_bLowestBr" class="kda-ts-16pt ln-150 nunito main-bw-300--text">
                {{ solution.strDesc }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDAHomeSolution',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  computed: {
    m_objTitle() {
      return {
        htmlText: this.$vuetify.lang.t('$vuetify.kda.home.solutions.title'),
        strComponent: 'h1'
      };
    },
    m_arrSolutions() {
      const result = [];

      for (let i = 1; i <= 4; i++) {
        result.push({
          strTitle: this.$vuetify.lang.t(`$vuetify.kda.home.solutions.item${i}.title`),
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.home.solutions.item${i}.desc`),
          imgSrc: require(`@/assets/photos/home-solution-${i}.png`)
        });
      }

      return result;
    }
  }
};
</script>
